// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/ubuntu/elapp-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dl-js": () => import("/home/ubuntu/elapp-website/src/pages/dl.js" /* webpackChunkName: "component---src-pages-dl-js" */),
  "component---src-pages-firebase-js": () => import("/home/ubuntu/elapp-website/src/pages/firebase.js" /* webpackChunkName: "component---src-pages-firebase-js" */),
  "component---src-pages-index-js": () => import("/home/ubuntu/elapp-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/home/ubuntu/elapp-website/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/ubuntu/elapp-website/.cache/data.json")

